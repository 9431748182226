<template>
  <default-layout>
    <div class="container request--book-container">
      <page-loading :show="loading" />
      <div class="left--col-container">
        <div class="request--book-content" v-if="bookingInfo">
          <listing-info :listing="bookingInfo.listing" />
          <checkout-form ref="checkoutForm" />
        </div>
      </div>
      <div class="right--col-container">
        <checkout-info
          v-if="bookingInfo"
          page-type="edit-booking"
          :bookingInfo="bookingInfo"
          :freeReservationFee="freeReservationFee"
          @submit="submit"
        />
      </div>
    </div>
    <modal-check-booking />
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ListingInfo from '@/components/utils/listing-info';
const PageLoading = () => import('@/components/content-loading/page-loading');
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const CheckoutForm = () => import('@/components/checkout/checkout-form.vue');
const CheckoutInfo = () => import('@/components/checkout/checkout-info.vue');

const ModalCheckBooking = () =>
  import('@/components/application-action/modals/booking/check-booking-modal');
export default {
  name: 'checkout-page',
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    PageLoading,
    ModalCheckBooking,
    CheckoutForm,
    ListingInfo,
    CheckoutInfo,
  },
  computed: {
    ...mapState({
      loading: (state) => state.checkout.loading,
      bookingInfo: (state) => state.checkout.bookingInfo,
      billingInfo: (state) => state.checkout.billingInfo,
      listingUuid: (state) => state.checkout.listingUuid,
      freeReservationFee: (state) => state.checkout.freeReservationFee,
    }),
  },
  async fetch({ store, query, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.dispatch('checkout/restoreInitialState');
    store.commit('checkout/SET_LISTING_UUID', query.listing);
  },
  async mounted() {
    if (!this.listingUuid) this.$router.back();
    await this.$store.dispatch('checkout/getData');
    setTimeout(() => {
      this.$refs.checkoutForm.$refs.billingInfo.applyRegionalData();
    }, 500);
  },
  methods: {
    async submit() {
      try {
        const valid = await this.$refs.checkoutForm.$refs.billingInfo.validate();
        if (valid) {
          const uuid = await this.$store.dispatch(
            'checkout/submit',
            this.$refs.checkoutForm.$refs.billingInfo.$refs.regionalForm.village.id,
          );
          this.$router.replace({
            path: '/mybooking/detail',
            query: {
              type: 'SELL_TRANSACTION',
              uuid,
            },
          });
        } else {
          this.scrollToErrorSection(this.$refs.checkoutForm.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
